import type { HeadlineV1Attributes } from '@/features/headline-v1/headline-v1-type';

import React from 'react';

import { Headline } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { logger } from '@/core/features/logger/logger';
import { headlineDesktopTitleVariants } from '@/features/headline-v1/headline-variants/headline-desktop-title-variants';
import { headlineSubtitleVariants } from '@/features/headline-v1/headline-variants/headline-subtitle-variants';
import { headlineTitleVariants } from '@/features/headline-v1/headline-variants/headline-title-variants';

export type HeadlineV1Props = HeadlineV1Attributes;

export default function HeadlineV1({
    htmlSubtitle,
    htmlTitle,
    subtitleStyle,
    subtitleType,
    titleStyle,
    titleType,
}: HeadlineV1Props) {
    const { isDesktopView } = useDeviceoutput();

    const titleVariant = React.useMemo(() => {
        const variant = isDesktopView ? headlineDesktopTitleVariants[titleStyle] : headlineTitleVariants[titleStyle];
        if (!variant) {
            logger.error(`Invalid titleStyle "${titleStyle}" in component HeadlineV1`);
        }
        return variant;
    }, [isDesktopView, titleStyle]);

    const subtitleVariant = React.useMemo(() => {
        const variant = subtitleStyle && headlineSubtitleVariants[subtitleStyle];
        if (htmlSubtitle && !variant) {
            logger.error(`Invalid subtitleStyle "${subtitleStyle}" in component HeadlineV1`);
        }
        return variant;
    }, [htmlSubtitle, subtitleStyle]);

    if (!titleVariant && !subtitleVariant) {
        return null;
    }

    return (
        <div className={'width-100'}>
            {titleVariant && (
                <Headline
                    htmlTitle={htmlTitle}
                    type={titleType}
                    {...titleVariant}
                />
            )}
            {htmlSubtitle && subtitleType && subtitleVariant && (
                <Headline
                    htmlTitle={htmlSubtitle}
                    type={subtitleType}
                    {...subtitleVariant}
                />
            )}
        </div>
    );
}
